import {
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Button,
  Typography,
  Paper,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import baseUrl from "../../config/apiConfig";
import { handleApiError } from "../common/Api-error-handler";
import { useDispatch } from "react-redux";
import { logout } from "../../store/auth/actions";
import { toast } from "react-toastify";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Country, State } from "country-state-city";
import { useTranslation } from "react-i18next";

interface PayPalOnApproveData {
  orderID: string;
}
interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  qr_code: Text;
  points: number;
  UserReferralPoints: {
    id: number;
    referral_name: string;
    points: string;
  }[];
}

export default function PaymentDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const cartItems = location.state?.cartItems || [];
  const currentUser = location.state?.currentUser;
  const paymentDetail = location.state?.paymentDetail;
  const shipping_address = location.state?.shipping_address;
  const orderType = location.state?.orderType;
  const eventId = location.state?.eventId;
  const [isLoading, setIsLoading] = useState(false);
  const count = paymentDetail.count;
  const subtotal = paymentDetail.subtotal;
  const discount = paymentDetail.discount;
  const tax = paymentDetail.tax;
  const grandTotal = paymentDetail.total;
  const [paymentMethod, setPaymentMethod] = useState("creditCard");
  const [shippingCost, setShippingCost] = useState(0);
  const [appliedDiscount, setAppliedDiscount] = useState(discount);
  const [total, setTotal] = useState(grandTotal);
  const [orderID, setOrderID] = useState<string | null>(null);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [users, setUsers] = useState<User | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [isPointsApplied, setIsPointsApplied] = useState(isDiscountApplied);
  const [countryCode, setCountryCode] = useState<string>("US");
  const [stateCode, setStateCode] = useState<string>("");
  const [setting, setSetting] = useState<any>(null);
  const { t } = useTranslation() as { t: (key: string) => string };

  useEffect(() => {
    // Get all countries
    if (orderType == "product") {
      setStateCode(shipping_address.shippingState);

      //   const countries = Country.getAllCountries();
      //   const country = countries.find(
      //     (country) => country.name === shipping_address.shippingCountry
      //   );

      //   if (country) {
      //     setCountryCode(country.isoCode);

      //     // Get states of the country
      //     const states = State.getStatesOfCountry(country.isoCode);
      //     const state = states.find(
      //       (state) => state.name === shipping_address.shippingState
      //     );

      //     if (state) {
      //       setStateCode(state.isoCode);
      //     }
      //   }
    }
  }, []);

  useEffect(() => {
    // Recalculate total when subtotal, applied discount, tax, or shipping cost changes
    const calculatedTotal = subtotal - appliedDiscount + tax + shippingCost;
    setTotal(calculatedTotal);

    const fetchUsers = async () => {
      try {
        const response = await axios.get<User>(
          `${baseUrl}/api/users/auth/userByIdWithPoints/${currentUser.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${currentUser?.token}`,
            },
          }
        );
        setUsers(response.data);
      } catch (error) {
        const { message, navigateTo } = handleApiError(error);
        toast.error(message);
        if (navigateTo) {
          if (navigateTo == "login") {
            dispatch(logout());
          }
          navigate(`/${navigateTo}`);
        }
      }
    };
    fetchUsers();
  }, [subtotal, appliedDiscount, tax, shippingCost]);

  useEffect(() => {
    const data = {
      cartItems: cartItems,
      countryCode: countryCode,
      countryState: stateCode,
    };
    if (countryCode && stateCode) {
      const fetchShippingRate = async () => {
        try {
          const response = await axios.post<any>(
            `${baseUrl}/api/orders/orders/get-shipping-rates`,
            data
          );
          if (typeof response.data[0].rate !== "number") {
            setShippingCost(parseFloat(response.data[0].rate));
          } else {
            setShippingCost(response.data[0].rate);
          }
        } catch (error) {
          const { message, navigateTo } = handleApiError(error);
          toast.error(message);
          if (navigateTo) {
            if (navigateTo == "login") {
              dispatch(logout());
            }
            navigate(`/${navigateTo}`);
          }
        }
      };
      fetchShippingRate();
    }
  }, [countryCode, stateCode]);

  useEffect(() => {
    const fetchSetting = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/users/settings/manertyPoints`
        );
        setSetting(response.data);
      } catch (error) {
        const { message, navigateTo } = handleApiError(error);
        toast.error(message);
        if (navigateTo) {
          if (navigateTo == "login") {
            dispatch(logout());
          }
          navigate(`/${navigateTo}`);
        }
      }
    };
    fetchSetting();
  }, []);

  const handlePaymentMethodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMethod(event.target.value);
  };
  // const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsDiscountApplied(event.target.checked);
  //   setPaymentMethod("creditCard");
  // };

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  // Function to apply or remove the discount
  const handleApplyDiscount = () => {
    if (isPointsApplied) {
      setAppliedDiscount(0);
      setIsPointsApplied(false);
    } else {
      const userPoints = users?.points || 0;
      if (userPoints > 0) {
        const discountAmount =
          userPoints * setting?.value > subtotal
            ? subtotal
            : userPoints * setting?.value;
        setAppliedDiscount(discountAmount);
      } else {
        setAppliedDiscount(0);
      }

      setIsPointsApplied(true);
    }
    setPaymentMethod("creditCard");
  };
  const handleSubmit = async (payment_detail: any, resultData: any) => {
    setIsLoading(true);
    const orderData = {
      orderType: orderType,
      userId: currentUser.user?.id,
      totalAmount: total,
      usedReferalPoints: appliedDiscount,
      shippingAddress: JSON.stringify(shipping_address),
      cartItems: cartItems,
      paymentMethod: paymentMethod,
      payment_detail: payment_detail,
      isDiscountApplied: isPointsApplied,
      eventId: eventId,
      ticketCount: count,
      printfulOrderResult: resultData,
    };

    try {
      // Call the order creation API
      const response = await axios.post(
        `${baseUrl}/api/orders/orders`,
        orderData,
        {
          headers: {
            Authorization: `Bearer ${currentUser?.token}`,
          },
        }
      );
      console.log(response.data)
      toast.success(response.data.message);

      // Navigate to the order confirmation page with relevant state data
      navigate("/order-confermation", {
        state: {
          count,
          subtotal,
          discount,
          tax,
          shippingCost,
          grandTotal: total, // Use calculated total
          orderId: response.data?.order?.id,
        },
      });
    } catch (error) {
      const { message, navigateTo } = handleApiError(error);
      toast.error(message);
      if (navigateTo) {
        if (navigateTo === "login") {
          dispatch(logout());
        }
        navigate(`/${navigateTo}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const createOrder = async (): Promise<string> => {
    try {
      const orderData = {
        orderType: orderType,
        userId: currentUser.user?.id,
        totalAmount: total,
        shippingAddress: JSON.stringify(shipping_address),
        cartItems: cartItems,
      };
      const response = await axios.post(
        `${baseUrl}/api/orders/orders/create-order-payment`,
        orderData,
        {
          headers: {
            Authorization: `Bearer ${currentUser?.token}`,
          },
        }
      );
      const data = await response.data;

      if (!data.id) {
        throw new Error("Order ID is missing from the response.");
      }

      setOrderID(data.id);
      return data.id;
    } catch (error) {
      const { message, navigateTo } = handleApiError(error);
      toast.error(message);
      if (navigateTo) {
        if (navigateTo === "login") {
          dispatch(logout());
        }
        navigate(`/${navigateTo}`);
      }

      throw error;
    }
  };

  // On approval function
  const onApprove = async (data: PayPalOnApproveData): Promise<void> => {
    try {
      let resultData: any = [];
      if (orderType == "product") {
        // Step 1: Create the order
        resultData = await handleOrderCreation(
          cartItems,
          shipping_address,
          stateCode,
          countryCode
        );

        // Step 2: Exit early if order creation fails
        if (!resultData) {
          setIsProcessing(false);
          toast.error("Order creation failed. Payment process stopped.");
          return;
        }
      }
      const response = await fetch(
        `${baseUrl}/api/orders/orders/create-order-payment-captured`,
        {
          method: "POST",
          body: JSON.stringify({
            orderID: data.orderID,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUser?.token}`,
          },
        }
      );
      const result = await response.json();
      handleSubmit(result, resultData);
    } catch (error) {
      const { message, navigateTo } = handleApiError(error);
      toast.error(message);
      if (navigateTo) {
        if (navigateTo === "login") {
          dispatch(logout());
        }
        navigate(`/${navigateTo}`);
      }
    }
  };

  const handleOrderCreation = async (
    cartItems: any,
    shippingAddress: any,
    stateCode: string,
    countryCode: string
  ) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/orders/orders/create-order-on-printful`,
        {
          cartItems,
          shippingAddress,
          stateCode,
          countryCode,
        },
        {
          headers: {
            Authorization: `Bearer ${currentUser?.token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      const { message, navigateTo } = handleApiError(error);
      toast.error(message);
      if (navigateTo) {
        if (navigateTo === "login") {
          dispatch(logout());
        }
        navigate(`/${navigateTo}`);
      }
    }
  };

  const handlePayment = async (event: FormEvent) => {
    event.preventDefault();
    setIsProcessing(true);
    if (!stripe || !elements) {
      toast.error("Stripe is not initialized.");
      setIsProcessing(false);
      return;
    }

    // Step 2: Check if the payment card is valid before proceeding
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      toast.error("Card element is not available.");
      setIsProcessing(false);
      return;
    }

    // Validate card details
    const { error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      toast.error(`Card validation failed: ${error.message}`);
      setIsProcessing(false);
      return; // Exit early if card validation fails
    }
    let resultData: any = [];
    if (orderType == "product") {
      // Step 1: Create the order
      resultData = await handleOrderCreation(
        cartItems,
        shipping_address,
        stateCode,
        countryCode
      );

      // Step 2: Exit early if order creation fails
      if (!resultData) {
        setIsProcessing(false);
        toast.error(t("paymentDetail.order_creation_failed_error"));
        return;
      }
    }
    // Step 3: Ensure Stripe and Elements are ready
    if (!stripe || !elements) {
      setIsProcessing(false);
      toast.error("Stripe is not initialized.");
      return;
    }

    try {
      // Prepare order data for payment intent
      const orderData = {
        orderType: orderType,
        userId: currentUser.user?.id,
        totalAmount: total,
        shippingAddress: JSON.stringify(shipping_address),
        cartItems: cartItems,
        paymentMethod: paymentMethod,
      };
      // Step 4: Create a payment intent on the backend
      const { data } = await axios.post(
        `${baseUrl}/api/orders/orders/create-order-payment`,
        orderData,
        {
          headers: {
            Authorization: `Bearer ${currentUser?.token}`,
          },
        }
      );

      // Step 5: Extract client secret for Stripe payment
      const clientSecret = data.clientSecret; // Ensure this is the correct key in the response
      const cardElement = elements.getElement(CardElement);

      // Step 6: Confirm card payment using Stripe
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement!,
          },
        }
      );

      // Handle payment result
      if (error) {
        setIsProcessing(false);
        toast.error(error.message || "Payment failed");
      } else if (paymentIntent?.status === "succeeded") {
        handleSubmit(paymentIntent, resultData); // Pass both payment and order data
        toast.success(t("paymentDetail.payment_successful_message"));
      }
    } catch (error) {
      const { message, navigateTo } = handleApiError(error);
      toast.error(message);
      if (navigateTo) {
        if (navigateTo === "login") {
          dispatch(logout());
        }
        navigate(`/${navigateTo}`);
      }
    } finally {
      setIsLoading(false);
      setIsProcessing(false);
    }
  };

  return (
    <Box width="100%" sx={{ flexGrow: 1, backgroundColor: "#FAFAFA" }}>
      <Grid
        container
        width="100%"
        direction="column"
        padding={{ xs: 2, sm: 3, md: 4 }}
        rowSpacing={2}
      >
        <Grid
          item
          xs={12}
          display="flex"
          sx={{
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: { xs: "10px", sm: "15px", md: "20px" },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Syne",
              fontSize: { xs: "20px", sm: "28px", md: "40px" },
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "120%",
            }}
          >
            {t("paymentDetail.payment_detail")}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={3}
          padding={{ xs: 2, sm: 3, md: 4 }}
        >
          <Grid item xs={12} md={7}>
            <Grid container direction="column" rowGap={3}>
              <Grid
                item
                xs={12}
                sx={{
                  border: "0.5px solid",
                  borderRadius: "20px",
                  padding: 5,
                }}
              >
                <Typography variant="h6">
                  {t("paymentDetail.select_payment_method")}
                </Typography>
                <form onSubmit={handlePayment}>
                  <FormControl component="fieldset" fullWidth>
                    <RadioGroup
                      aria-label="payment-method"
                      name="payment-method"
                      value={paymentMethod}
                      onChange={handlePaymentMethodChange}
                    >
                      {/* Credit Card Option */}
                      <Box mb={2} display="flex" flexDirection="column">
                        <FormControlLabel
                          value="creditCard"
                          control={<Radio />}
                          label={t("paymentDetail.credit_Debit_card")}
                          sx={{
                            marginBottom: 2,
                          }}
                        />
                        {paymentMethod === "creditCard" && (
                          // <Box mt={1} padding={2} bgcolor="background.paper">
                          //   <Typography variant="h6">
                          //     Credit Card Information
                          //   </Typography>
                          //   <TextField
                          //     label="Card Number"
                          //     variant="outlined"
                          //     fullWidth
                          //     margin="normal"
                          //     value={cardNumber}
                          //     onChange={(e) => setCardNumber(e.target.value)}
                          //     error={!!errors.cardNumber}
                          //     helperText={errors.cardNumber}
                          //   />

                          //   <TextField
                          //     label="Name On Card"
                          //     variant="outlined"
                          //     fullWidth
                          //     margin="normal"
                          //     value={nameOnCard}
                          //     onChange={(e) => setNameOnCard(e.target.value)}
                          //     error={!!errors.nameOnCard}
                          //     helperText={errors.nameOnCard}
                          //   />
                          //   <Grid container spacing={2} mt={1}>
                          //     <Grid item xs={12} sm={6}>
                          //       <TextField
                          //         label="Expiry Date"
                          //         variant="outlined"
                          //         fullWidth
                          //         margin="normal"
                          //         value={expiryDate}
                          //         onChange={(e) =>
                          //           setExpiryDate(e.target.value)
                          //         }
                          //         error={!!errors.expiryDate}
                          //         helperText={errors.expiryDate}
                          //       />
                          //     </Grid>
                          //     <Grid item xs={12} sm={6}>
                          //       <TextField
                          //         label="CVV"
                          //         variant="outlined"
                          //         fullWidth
                          //         margin="normal"
                          //         value={cvv}
                          //         onChange={(e) => setCvv(e.target.value)}
                          //         error={!!errors.cvv}
                          //         helperText={errors.cvv}
                          //       />
                          //     </Grid>
                          //   </Grid>
                          // </Box>
                          <Box marginBottom={2}>
                            <CardElement
                              options={{
                                style: {
                                  base: {
                                    fontSize: "22px",
                                    color: "#424770",
                                    "::placeholder": {
                                      color: "#aab7c4",
                                    },
                                  },
                                  invalid: {
                                    color: "#9e2146",
                                  },
                                },
                                hidePostalCode: true,
                              }}
                            />
                            <Button
                              type="submit"
                              variant="contained"
                              size="large"
                              fullWidth
                              disabled={!stripe || isProcessing}
                              sx={{ marginTop: 5 }}
                            >
                              {isProcessing
                                ? "Processing..."
                                : `Pay $  ${total.toFixed(2)}`}
                            </Button>
                          </Box>
                        )}
                      </Box>

                      {/* PayPal Option */}
                      <Box mb={2} display="flex" flexDirection="column">
                        <FormControlLabel
                          value="paypal"
                          control={<Radio />}
                          label="PayPal"
                        />
                        {paymentMethod === "paypal" && (
                          <Typography variant="h6" textAlign={"center"}>
                            {t("paymentDetail.paypal_coming_soon")}
                          </Typography>
                          // <PayPalScriptProvider
                          //   options={{
                          //     clientId:
                          //       process.env.REACT_APP_PAYPAL_CLIENT_ID || "",
                          //   }}
                          // >
                          //   <PayPalButtons
                          //     createOrder={createOrder}
                          //     onApprove={onApprove}
                          //     style={{
                          //       layout: "vertical",
                          //       color: "gold",
                          //       shape: "pill",
                          //       label: "pay",
                          //     }}
                          //   />
                          // </PayPalScriptProvider>
                        )}
                      </Box>

                      {/* Venmo Option */}
                      <Box mb={2} display="flex" flexDirection="column">
                        <FormControlLabel
                          value="venmo"
                          control={<Radio />}
                          label="AfterPay"
                        />
                        {paymentMethod === "venmo" && (
                          <Box mt={1} padding={2} bgcolor="background.paper">
                            <Typography variant="h6" textAlign={"center"}>
                              {t("paymentDetail.afterpay_coming_soon")}
                            </Typography>
                            {/* <TextField
                              label="AfterPay Username"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              value={venmoUsername}
                              onChange={(e) => setVenmoUsername(e.target.value)}
                              error={!!errors.venmoUsername}
                              helperText={errors.venmoUsername}
                            />*/}
                          </Box>
                        )}
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </form>
              </Grid>
              {/* <Grid
                item
                xs={12}
                sx={{
                  border: "0.5px solid",
                  borderRadius: "20px",
                  padding: 2,
                }}
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: { xs: "12px", sm: "24px" },
                    fontWeight: 600,
                  }}
                >
                  Save Information
                </Typography>
                <Box mt={2}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Save my information for future transactions"
                  />
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper
              elevation={10}
              sx={{
                padding: { xs: 2, sm: 3, md: 4 },
                backgroundColor: "#FFE7DB",
                border: "1px solid",
                borderRadius: "15px",
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {t("your_order")}
                  </Typography>
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                  mb={2}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontFamily: "Outfit",
                    }}
                  >
                    {t("product")} x {count}
                  </Typography>
                  <Typography variant="body1">
                    ${subtotal.toFixed(2)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                  mb={2}
                >
                  <Typography
                    variant="body2"
                    color="white"
                    sx={{
                      backgroundColor: "#FF5A00",
                      borderRadius: 2,
                      padding: 1,
                    }}
                  >
                    {t("paymentDetail.use_your_redom_points")}{" "}
                    {users?.points ?? 0}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => setOpenModal(true)}
                  >
                    {isPointsApplied ? "Remove" : "Apply"}
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                  mb={2}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontFamily: "Outfit",
                    }}
                  >
                    {t("subTotal")}
                  </Typography>
                  <Typography variant="body1">
                    ${subtotal.toFixed(2)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                  mb={2}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontFamily: "Outfit",
                    }}
                  >
                    {t("discount")}{" "}
                    <span style={{ color: "green" }}>
                      {" "}
                      {isPointsApplied
                        ? t(
                            "paymentDetail.your_redom_point_discount_is_applied"
                          )
                        : ""}
                    </span>
                  </Typography>
                  <Typography variant="body1">
                    ${appliedDiscount.toFixed(2)}
                  </Typography>
                </Box>

                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                  mb={2}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontFamily: "Outfit",
                    }}
                  >
                    Tax
                  </Typography>
                  <Typography variant="body1">${tax.toFixed(2)}</Typography>
                </Box> */}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                  mb={2}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontFamily: "Outfit",
                    }}
                  >
                    {t("shipping_cost")}
                  </Typography>
                  <Typography variant="body1">
                    ${shippingCost.toFixed(2)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                  mb={2}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontFamily: "Outfit",
                    }}
                  >
                    {t("Grand_Total")}
                  </Typography>
                  <Typography variant="body1">${total.toFixed(2)}</Typography>
                </Box>

                {/* <Grid item xs={12} textAlign="center">
                      <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="primary"
                          type="submit"
                          sx={{ padding: { xs: '10px', sm: '15px' } }}
                          onClick={handleSubmit}
                      >
                          Place Your Order
                      </Button>
                  </Grid> */}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{ backgroundColor: "#FFE7DB" }}>
          <DialogTitle textAlign={"center"}>
            {isPointsApplied
              ? t("paymentDetail.remove_discount")
              : t("paymentDetail.apply_discount")}
          </DialogTitle>
          <DialogContent>
            <Typography>
              {isPointsApplied
                ? t("paymentDetail.already_applied_discount")
                : t(
                    "paymentDetail.do_you_want_to_apply_your_points_as_a_discount"
                  )}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="primary">
              {t("cancel_button")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleApplyDiscount(); // Apply or remove the discount
                setOpenModal(false); // Close the modal
              }}
              color="primary"
            >
              {isPointsApplied ? t("removeButton") : t("applyButton")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}
