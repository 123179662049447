import { Typography, Grid, Container, Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoBlackSvg from "../../assets/logos/LogoDefault.svg";
import { useTranslation } from "react-i18next";

const formatDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return date.toLocaleDateString(undefined, options); // Uses the user's locale
};
export default function OrderConfermation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const {
    orderNumber = "123456789",
    orderDate = "August 12, 2024",
    shippingAddress = "1234 Elm Street, Springfield, IL",
    billingAddress = "1234 Elm Street, Springfield, IL",
    items = [
      { name: "Product 1", quantity: 1, price: 19.99 },
      { name: "Product 2", quantity: 2, price: 29.99 },
    ],
    subtotal = 0,
    discount = 0,
    shippingCost = 0,
    tax = 0,
    grandTotal = 0,
    orderId = "34563",
  } = state || {};
  const currentDate = formatDate(new Date());
  const { t } = useTranslation() as { t: (key: string) => string };

  return (
    <Box width="100%">
      <Grid
        container
        width="100%"
        direction="column"
        padding={{ xs: 2, sm: 3, md: 4 }}
        rowSpacing={2}
        sx={{ marginBottom: "40px" }}
      >
        <Grid
          item
          xs={12}
          display="flex"
          sx={{
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: { xs: "10px", sm: "15px", md: "20px" },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Syne",
              fontSize: { xs: "20px", sm: "28px", md: "40px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "120%",
            }}
          >
            {t("orders.order_confirmation")}
          </Typography>
        </Grid>
        <Container
          component="main"
          sx={{
            border: "1px solid",
            boxShadow: "2",
            borderRadius: "20px",
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="center"
            padding={{ xs: 2, sm: 3, md: 4 }}
          >
            <Grid item xs={12}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  src={LogoBlackSvg}
                  alt="logo"
                  style={{ width: "30%", height: "200px" }}
                />
              </Box>
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: { xs: "24px", sm: "28px", md: "32px" },
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {t("orders.thank_you_for_order")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: { xs: "10px", sm: "14px", md: "18px" },
                    textAlign: "center",
                  }}
                >
                  {t("orders.confirmation_message")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  fontFamily: "Outfit",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {t("orders.transaction_date")}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Outfit",
                  fontSize: "18px",
                }}
              >
                {currentDate}
              </Typography>
              <Box display={"flex"} gap={1}>
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Order No.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "18px",
                  }}
                >
                  #{orderId}
                </Typography>
              </Box>
              <Box mt={3}>
                <Grid container>
                  <Grid item xs={6}>
                    {t("subTotal")}
                    <Typography variant="body1"></Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      $ {subtotal.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1"> {t("discount")}</Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      $ {discount.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {t("shipping_cost")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      $ {shippingCost.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1">{t("tax")}</Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      $ {tax.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h6">{t("Grand_Total")}</Typography>
                  </Grid>
                  <Grid item xs={6} textAlign="right">
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      $ {grandTotal.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3} textAlign="center">
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={() => navigate("/products")}
                >
                  {t("orders.continue_Shopping")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Box>
  );
}
