import {
  Grid,
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  Checkbox,
  FormControlLabel,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState, ChangeEvent, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Country, State } from "country-state-city";
import { useTranslation } from "react-i18next";

interface FormValues {
  shippingFullName: string;
  shippingEmail: string;
  shippingPhoneNumber: string;
  shippingDeliveryAddress: string;
  shippingCountry: string;
  shippingCity: string;
  shippingState: string;
  shippingPostalCode: string;
  billingFullName: string;
  billingEmail: string;
  billingPhoneNumber: string;
  billingDeliveryAddress: string;
  billingCity: string;
  billingState: string;
  billingPostalCode: string;
}

interface ValidationErrors {
  shippingFullName?: string;
  shippingEmail?: string;
  shippingPhoneNumber?: string;
  shippingDeliveryAddress?: string;
  shippingCity?: string;
  shippingState?: string;
  shippingCountry?: string;
  shippingPostalCode?: string;
  billingFullName?: string;
  billingEmail?: string;
  billingPhoneNumber?: string;
  billingDeliveryAddress?: string;
  billingCity?: string;
  billingState?: string;
  billingPostalCode?: string;
  discountCode?: string;
}

const ShippinginfoPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cartItems = location.state?.cartItems || [];
  const currentUser = location.state?.currentUser;
  const subTotal = location.state?.subtotal;
  const usStates = State.getStatesOfCountry("US");
  const count = location.state?.totalItems || 0;

  const [formValues, setFormValues] = useState<FormValues>({
    shippingFullName: "",
    shippingEmail: "",
    shippingPhoneNumber: "",
    shippingDeliveryAddress: "",
    shippingCity: "",
    shippingState: "",
    shippingCountry: "",
    shippingPostalCode: "",
    billingFullName: "",
    billingEmail: "",
    billingPhoneNumber: "",
    billingDeliveryAddress: "",
    billingCity: "",
    billingState: "",
    billingPostalCode: "",
  });

  const [errors, setErrors] = useState<ValidationErrors>({});
  const [discountCode, setDiscountCode] = useState("");
  const [discountApplied, setDiscountApplied] = useState(false);
  const [sameAddress, setSameAddress] = useState(false);
  const { t } = useTranslation() as { t: (key: string) => string };

  useEffect(() => {
    if (currentUser) {
      setFormValues((prevValues) => ({
        ...prevValues,
        shippingFullName: currentUser?.user.fullname || "",
        shippingEmail: currentUser?.user.address_email || "",
        shippingPhoneNumber: currentUser?.user.phone || "",
        shippingDeliveryAddress: currentUser?.user.address || "",
        shippingCity: currentUser?.user.city || "",
        shippingState: currentUser?.user.state || "",
        shippingCountry: currentUser?.user.country || "",
        shippingPostalCode: currentUser?.user.postalCode || "",
      }));
    }
  }, [currentUser]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedValues = { ...formValues, [name]: value };
    setFormValues(updatedValues);
    localStorage.setItem("formValues", JSON.stringify(updatedValues));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>, field: string) => {
    const updatedValues = { ...formValues, [field]: e.target.value };
    setFormValues(updatedValues);
    localStorage.setItem("formValues", JSON.stringify(updatedValues));
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSameAddress(e.target.checked);

    let updatedValues;
    if (e.target.checked) {
      updatedValues = {
        ...formValues,
        billingFullName: formValues.shippingFullName,
        billingEmail: formValues.shippingEmail,
        billingPhoneNumber: formValues.shippingPhoneNumber,
        billingDeliveryAddress: formValues.shippingDeliveryAddress,
        billingCity: formValues.shippingCity,
        billingState: formValues.shippingState,
        billingPostalCode: formValues.shippingPostalCode,
      };
    } else {
      updatedValues = {
        ...formValues,
        billingFullName: "",
        billingEmail: "",
        billingPhoneNumber: "",
        billingDeliveryAddress: "",
        billingCity: "",
        billingState: "",
        billingPostalCode: "",
      };
    }

    setFormValues(updatedValues);
    localStorage.setItem("formValues", JSON.stringify(updatedValues));
  };

  useEffect(() => {
    const savedFormValues = localStorage.getItem("formValues");
    if (savedFormValues) {
      setFormValues(JSON.parse(savedFormValues));
    }
  }, []);

  const validate = (): boolean => {
    const newErrors: ValidationErrors = {};

    // Regular expression to validate email format
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Shipping Address Validation
    if (!formValues.shippingFullName)
      newErrors.shippingFullName = t("shippingInfo.validation_error.name");
    if (!formValues.shippingEmail) {
      newErrors.shippingEmail = t("shippingInfo.validation_error.email");
    } else if (!emailPattern.test(formValues.shippingEmail)) {
      newErrors.shippingEmail = t("shippingInfo.validation_error.valid_email");
    }
    if (!formValues.shippingPhoneNumber)
      newErrors.shippingPhoneNumber = t(
        "shippingInfo.validation_error.phoneNumber"
      );
    if (!formValues.shippingDeliveryAddress)
      newErrors.shippingDeliveryAddress = t(
        "shippingInfo.validation_error.deliveryAddress"
      );

    if (!formValues.shippingState) {
      newErrors.shippingState = t("shippingInfo.validation_error.state");
    }
    if (!formValues.shippingCity)
      newErrors.shippingCity = t("shippingInfo.validation_error.city");
    if (!formValues.shippingPostalCode)
      newErrors.shippingPostalCode = t(
        "shippingInfo.validation_error.postalCode"
      );

    // Billing Address Validation (only if sameAddress is false)
    if (!sameAddress) {
      if (!formValues.billingFullName)
        newErrors.billingFullName = t("shippingInfo.validation_error.name");
      if (!formValues.billingEmail) {
        newErrors.billingEmail = t("shippingInfo.validation_error.email");
      } else if (!emailPattern.test(formValues.billingEmail)) {
        newErrors.billingEmail = t("shippingInfo.validation_error.valid_email");
      }
      if (!formValues.billingPhoneNumber)
        newErrors.billingPhoneNumber = t(
          "shippingInfo.validation_error.phoneNumber"
        );
      if (!formValues.billingDeliveryAddress)
        newErrors.billingDeliveryAddress = t(
          "shippingInfo.validation_error.deliveryAddress"
        );
      if (!formValues.billingCity)
        newErrors.billingCity = t("shippingInfo.validation_error.city");
      if (!formValues.billingState)
        newErrors.billingState = t("shippingInfo.validation_error.state");
      if (!formValues.billingPostalCode)
        newErrors.billingPostalCode = t(
          "shippingInfo.validation_error.postalCode"
        );
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      navigate("/payment-detail", {
        state: {
          orderType: "product",
          cartItems: cartItems,
          currentUser: currentUser,
          paymentDetail: {
            count: cartItems.length,
            discount: discountApplied ? 20 : 0,
            tax: 0,
            shippingCost: 0,
            subtotal: subTotal,
            total: subtotal - discount + tax + shippingCost,
          },
          shipping_address: formValues,
        },
      });
    }
  };

  // Calculate order totals with discount
  const ticketsPrice = 50;
  const discount = discountApplied ? 20 : 0;
  const tax = 0;
  const shippingCost = 0;
  const subtotal = subTotal;
  const total = subtotal - discount + tax + shippingCost;

  return (
    <Container maxWidth={"xl"}>
      <Grid
        container
        width="100%"
        direction="row"
        padding={{ xs: 2, sm: 3, md: 4 }}
        spacing={2}
        flexShrink={0}
      >
        <Grid item xs={12} md={8}>
          <Grid container xs={12}>
            <Grid
              item
              xs={12}
              sx={{
                border: "1px solid",
                borderRadius: "20px",
                padding: "20px",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Outfit",
                  fontSize: "24px",
                  fontWeight: 600,
                  lineHeight: "60px",
                }}
              >
                {t("shippingInfo.shipping_address")}
              </Typography>
              <TextField
                name="shippingFullName"
                label={t("shippingInfo.input_labels.full_name")}
                value={formValues.shippingFullName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.shippingFullName}
                helperText={errors.shippingFullName}
              />
              <Grid container spacing={2} margin="normal">
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="shippingInfo.input_labels.shippingEmail"
                    label={t("email")}
                    value={formValues.shippingEmail}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.shippingEmail}
                    helperText={errors.shippingEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="shippingPhoneNumber"
                    label={t("shippingInfo.input_labels.phone_number")}
                    value={formValues.shippingPhoneNumber}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.shippingPhoneNumber}
                    helperText={errors.shippingPhoneNumber}
                  />
                </Grid>
              </Grid>
              <TextField
                name="shippingDeliveryAddress"
                label={t("shippingInfo.input_labels.delivery_address")}
                value={formValues.shippingDeliveryAddress}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.shippingDeliveryAddress}
                helperText={errors.shippingDeliveryAddress}
              />
              <Grid container spacing={2} margin="normal">
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="shippingCountry"
                    label={t("shippingInfo.input_labels.country")}
                    value="United States"
                    placeholder="United States"
                    disabled
                    fullWidth
                    margin="normal"
                    error={!!errors.shippingCountry}
                    helperText={errors.shippingCountry}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} margin="normal">
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="shippingCity"
                    label={t("shippingInfo.input_labels.city")}
                    value={formValues.shippingCity}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.shippingCity}
                    helperText={errors.shippingCity}
                  />
                </Grid>

                <Grid item xs={12} sm={6} mt={2}>
                  <FormControl fullWidth>
                    <InputLabel>
                      {" "}
                      {t("shippingInfo.input_labels.state")}
                    </InputLabel>
                    <Select
                      label={t("shippingInfo.input_labels.state")}
                      value={formValues.shippingState}
                      onChange={(e) => handleSelectChange(e, "shippingState")}
                      error={!!errors.shippingState}
                    >
                      {usStates.map((state) => (
                        <MenuItem key={state.isoCode} value={state.isoCode}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography color="error">
                      {errors.shippingState}
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>

              <TextField
                name="shippingPostalCode"
                label={t("shippingInfo.input_labels.postalCode")}
                value={formValues.shippingPostalCode}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.shippingPostalCode}
                helperText={errors.shippingPostalCode}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                border: "1px solid",
                borderRadius: "20px",
                padding: "20px",
                marginTop: "10px",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    fontWeight: 600,
                    lineHeight: "60px",
                  }}
                >
                  {t("shippingInfo.billing_address")}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sameAddress}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={t("shippingInfo.input_labels.same_as_shipping")}
                />
              </Box>

              <TextField
                name="billingFullName"
                label={t("shippingInfo.input_labels.full_name")}
                value={formValues.billingFullName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.billingFullName}
                helperText={errors.billingFullName}
                disabled={sameAddress}
              />
              <Grid container spacing={2} margin="normal">
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="billingEmail"
                    label={t("email")}
                    value={formValues.billingEmail}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.billingEmail}
                    helperText={errors.billingEmail}
                    disabled={sameAddress}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="billingPhoneNumber"
                    label={t("shippingInfo.input_labels.phone_number")}
                    value={formValues.billingPhoneNumber}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.billingPhoneNumber}
                    helperText={errors.billingPhoneNumber}
                    disabled={sameAddress}
                  />
                </Grid>
              </Grid>
              <TextField
                name="billingDeliveryAddress"
                label={t("shippingInfo.input_labels.delivery_address")}
                value={formValues.billingDeliveryAddress}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.billingDeliveryAddress}
                helperText={errors.billingDeliveryAddress}
                disabled={sameAddress}
              />
              <Grid container spacing={2} margin="normal">
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="billingCity"
                    label={t("shippingInfo.input_labels.city")}
                    value={formValues.billingCity}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.billingCity}
                    helperText={errors.billingCity}
                    disabled={sameAddress}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="billingState"
                    label={t("shippingInfo.input_labels.state")}
                    value={formValues.billingState}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    error={!!errors.billingState}
                    helperText={errors.billingState}
                    disabled={sameAddress}
                  />
                </Grid>
              </Grid>
              <TextField
                name="billingPostalCode"
                label={t("shippingInfo.input_labels.postalCode")}
                value={formValues.billingPostalCode}
                onChange={handleChange}
                fullWidth
                margin="normal"
                error={!!errors.billingPostalCode}
                helperText={errors.billingPostalCode}
                disabled={sameAddress}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper
            elevation={10}
            sx={{
              padding: { xs: 2, sm: 3, md: 4 },
              backgroundColor: "#FFE7DB",
              border: "1px solid",
              borderRadius: "15px",
            }}
          >
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">{t("your_order")}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                container
                justifyContent="flex-end"
              ></Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: { xs: "12px", sm: "24px" },
                    fontWeight: 600,
                  }}
                >
                  {t("total_items")} x {count}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent="flex-end">
                <Typography variant="body1">${subtotal.toFixed(2)}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">{t("subTotal")}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent="flex-end">
                <Typography variant="body1">${subtotal.toFixed(2)}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">{t("Grand_Total")}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent="flex-end">
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: { xs: "12px", sm: "24px" },
                    fontWeight: 600,
                  }}
                >
                  ${total.toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ padding: { xs: "10px", sm: "15px" } }}
                  onClick={handleSubmit}
                >
                  {t("continue_to_payment")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ShippinginfoPage;
